import React from 'react'
import PropTypes from 'prop-types'
import styles from './DocFooter.module.scss'

export default function DocFooter({ post, url, externalName }) {
  return <footer className={styles.footer} />
}

DocFooter.propTypes = {
  post: PropTypes.object,
  url: PropTypes.string,
  externalName: PropTypes.string
}
